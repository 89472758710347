#home {
    position: relative;
    // background: url('../../assets/bgIMG.png');
    background-color: #f7f6f6;
    background-size: cover;
    background-repeat: repeat;
    background-position: center; 

    .app__wrapper {
        padding: 0;

        .copyright {
            display: none;
        }
    }
}

.app__header{
    flex: 1;
    height: 100%;
    width: 100%;
    flex-direction: colum; 
    // padding: 6rem 2rem 0rem;

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }
    
    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }

    @media screen and (max-width: 200px) {
        padding: 6rem 1rem 2rem;
    }
}

.app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    margin-left: 5rem;

    @media screen and (max-width: 2000px) {
        width: 100%;
        margin-right: 0rem;
    }
}

.p-intro {
    color: var(--dark-gray-color);
    font-size: 2rem;
    font-weight: 700;
}

.p-job {
    color: var(--dark-gray-color);
    font-size: 1.2rem; 
    font-weight: 500; 
    margin-bottom: 10px;
}

.p-job-link {
    color: var(--dark-green-color);
    font-size: 1.2rem; 
    font-weight: 500; 
    margin-bottom: 10px;
}

.p-bio {
    line-height: 1.5;
}

.spacing {
    margin-top: 10px;
}


.header-small {
    display: flex !important;
    background-color: var(--primary-color);
    @media screen and (max-width: 900px) {
        flex-direction: column !important;
        margin-top: 3rem !important;
        margin-left: 0rem !important; 
    }
}

.app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end; 
    margin-top: 3rem;

    .badge-cmp, .tag-cmp {
        padding: 1rem 2rem;
        border: var(--white-color);
        border-radius: 15px; 
        width: auto;  
    }

    .tag-cmp {
        flex-direction: row;
        margin-top: 7rem;
        // margin-left: 10rem;
        min-width: 30rem;

        p {
            text-align: left;
        } 

        img {
            width: 100%;
        }

        .bio-text {
            margin-right: 5rem;
            z-index: 1;
            background-color: var(--primary-color);
        }
        
   
    }



    span {
        font-size: 2.5rem;

        @media screen and (min-width: 2000px) {
            font-size: 5rem;
        }
    }

    @media screen and (max-width: 1200px) {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;
  
    div {
      width: 100px;
      height: 100px;
      border-radius: 50%;
  
      background: var(--white-color);
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  
      img {
        width: 70%;
        height: 70%;
      }
    }

    div:nth-child(1) {
        width: 100px;
        height: 100px;
    }

    div:nth-child(2) {
        width: 150px;
        height: 150px;
        margin: 1.75rem;
    }

    div:nth-child(3) {
        width: 70px;
        height: 70px;
    }

     

    @media screen and (min-width: 2000px) {
        div:nth-child(1) {
            width: 500px !important;
            height: 500px !important;
        }
    
        div:nth-child(2) {
            width: 400px;
            height: 400px;  
        }
    
        div:nth-child(3) {
            width: 200px;
            height: 200px;
        }
    }


    @media screen and (max-width: 1200px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div {
            margin: 1rem;
        }
    }
}

  .location-cmp {
    position: absolute; /* Allows placement relative to the page or parent */
    left: 0; /* Align to the far left */
    top: 50%;  
    transform: translateY(-50%); /* Adjust to center vertically */ 


    @media screen and (max-width: 900px) {
        display: none;
    }
  }
  

  .location-badge {
    background-color: var(--dark-green-color);
    color: white;
    border-radius: 0 5px 5px 0;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
  }
  
  .globe-icon {
    width: 30px;
    height: 30px;
    margin-left: 0.5rem;
  }
  
  .location-badge:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }

  .p-focus {
    font-size: 1rem;
  }


  .app__header-img {
    position: relative;
    display: inline-block; // Ensures the bubble is positioned relative to the image
    cursor: pointer; // Makes it clear the image is interactive
  }
  
  .hover-bubble {
    position: absolute;
    bottom: 10%; // Position the text bubble above the image
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.7); // Dark background for contrast
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    font-size: 1rem;
    white-space: nowrap;
    pointer-events: none; // Prevent blocking hover effect
    opacity: 0; // Hidden by default
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; // Smooth transition
  }
  