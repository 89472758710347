.app__article {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  
  .app__article-item {
    width: 60%;
    min-height: 320px;
    background-color: var(--white-color);
    display: flex;
    flex-direction: row;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  
    transition: all 0.3s ease-in-out;
  
    img {
      width: 300px;
      height: 300px;
      border-radius: 10px;
      object-fit: cover;
      margin-top: 2rem;
      // border: 3px solid rgba(0, 0, 0, 0.03); 
      filter: drop-shadow(0.75px 1px 6px rgba(0, 0, 0, 0.03));
    }
  
    @media screen and (min-width: 2000px) {
      min-height: 450px;
     
      img {
        width: 150px;
        height: 150px;
      }
    }
  
    @media screen and (max-width: 1400px) {
      width: 100%;

      img {
        width: 400px;
        height: 400px;
      }
    }
  
    @media screen and (max-width: 700px) {
      flex-direction: column; 
      padding-top: 0px;
      img {
        width: 300px;
        height: 300px;
        display: none;
      }
    }
  }
  
  .app__article-content {
    flex: 1;
    padding: 0 2rem;
    text-align: left; 
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    // align-items: flex-start;
  
    p {
      line-height: 2rem;
      color: var(--black-color);
      font-family: var(--font-base);
  
      @media screen and (min-width: 2000px) {
        font-size: 2rem;
        line-height: 3.5rem;
      }
    }
  
    h4 {
      font-weight: 600;
      color: var(--secondary-color);
      // margin-top: 2rem;
    }
  
    h5 {
      font-weight: 400;
      color: var(--gray-color);
      margin-top: 5px;
    }
  
    .summary {
      margin-top: 1rem;
      margin-bottom: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 12; /* number of lines to show */
      line-clamp: 12; /* number of lines to show */
      -webkit-box-orient: vertical;
    }

    .continue {
      color: #182a20;
      text-decoration: underline;

      &:hover {
        font-weight: bolder;
      }
    }

    @media screen and (max-width: 700px) {
      margin-top: 2rem;
      padding: 0;
    }
  }
  
  .app__article-btns {
    flex-direction: row;
    margin-top: 1rem;
  
    div {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: var(--white-color);
  
      margin: 1rem;
      transition: all 0.3s ease-in-out;
  
      svg {
        width: 20px;
        height: 20px;
        color: var(--secondary-color);
      }
  
      &:hover {
        background-color: var(--secondary-color);
  
        svg {
          color: var(--white-color);
        }
      }
  
      @media screen and (min-width: 2000px) {
        width: 100px;
        height: 100px;
  
        svg {
          width: 45px;
          height: 45px;
        }
      }
    }
  }
  
  .app__article-brands {
    width: 80%;
    flex-wrap: wrap;
    margin-top: 2rem;
  
    div {
      width: 150px;
      margin: 1.5rem;
  
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        filter: grayscale(1); 
      }
  
      &:hover {
        img {
          filter: grayscale(0);
        }
      }
  
      @media screen and (min-width: 2000px) {
        width: 210px;
        margin: 2rem;
      }
  
      @media screen and (max-width: 450px) {
        width: 120px;
        margin: 1rem;
      }
    }
  
    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }

.title {
  padding-bottom: 2rem; 
}
 