@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #f7f6f6;
  --secondary-color: #242424; 
  --tan-color: #f7f6f6;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
  --dark-green-color: #4c615c;
  --light-green-color: #6d8b85;
  --dark-gray-color: #242424;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

html, body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}